/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "SFDisplayPro";
  src: url("SFPRODISPLAYREGULAR.OTF") format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFDisplayPro";
  src: url("SFPRODISPLAYMEDIUM.OTF") format("otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SFDisplayPro";
  src: url("SFPRODISPLAYBOLD.OTF") format("otf");
  font-weight: 700;
  font-style: normal;
}

