@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;900&display=swap');
@import url("https://use.typekit.net/vvk1sgp.css");
@import url("../assets/fonts/SFProDisplay/stylesheet.css");
@import url("../assets/fonts/GoogleSans/stylesheet.css");
@import url("../assets/fonts/Gordita/stylesheet.css");
@import url("../assets/fonts/Gotham/stylesheet.css");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

input {
    letter-spacing: 0px!important;
}


