/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "GoogleSans";
  src: url("ProductSans-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans";
  src: url("ProductSans-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans";
  src: url("ProductSans-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans";
  src: url("ProductSans-Black.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
}

