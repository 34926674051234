/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "gotham";
  src: url("Gotham-Black.otf") format("otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("Gotham-Bold.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("GothamMedium.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("GothamLight.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}


