@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;900&display=swap);
@import url(https://use.typekit.net/vvk1sgp.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "SFDisplayPro";
  src: url(/static/media/SFPRODISPLAYREGULAR.d1f5e6e1.OTF) format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFDisplayPro";
  src: url(/static/media/SFPRODISPLAYMEDIUM.b85d841d.OTF) format("otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SFDisplayPro";
  src: url(/static/media/SFPRODISPLAYBOLD.b1f4d2c6.OTF) format("otf");
  font-weight: 700;
  font-style: normal;
}


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "GoogleSans";
  src: url(/static/media/ProductSans-Regular.2765c696.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans";
  src: url(/static/media/ProductSans-Medium.50b60017.ttf) format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans";
  src: url(/static/media/ProductSans-Bold.153c4f86.ttf) format("ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans";
  src: url(/static/media/ProductSans-Black.99679fcb.ttf) format("ttf");
  font-weight: 800;
  font-style: normal;
}


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "gordita";
  src: url(/static/media/gordita_medium-webfont.2d938008.woff2) format("woff2"),
    url(/static/media/gordita_medium-webfont.c4b45d80.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "gordita";
  src: url(/static/media/gordita_regular-webfont.315a3b3d.woff2) format("woff2"),
    url(/static/media/gordita_regular-webfont.806ea727.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 23, 2021 */

@font-face {
  font-family: "gotham";
  src: url(/static/media/Gotham-Black.c9e42599.otf) format("otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url(/static/media/Gotham-Bold.f9a5c924.otf) format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url(/static/media/GothamMedium.0a910c0b.ttf) format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url(/static/media/GothamLight.03e7ff60.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
}



input {
    letter-spacing: 0px!important;
}



